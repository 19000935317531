<template>
  <footer class="content-footer">
    <div class="content-footer-itens container">
      <div class="row">
        <div class="col-lg-6">
          <div class="content-footer-itens-element">
            <div class="content-footer-itens-element-header">
              <p>contactos</p>
            </div>
            <div class="content-footer-itens-element--links">
              <!-- <a href="tel:351808000000">808 00 00 00</a> -->
              <a href="mailto:clientesamigosespana@gruponabeiro.com" target="_blank" rel="noopener">clientesamigosespana@gruponabeiro.com</a>
            </div>
          </div>
        </div>
      </div>
      <div class="content-footer-itens-copyright">
        <div class="content-footer-itens-copyright--text">
          <p>©  {{ (new Date().getFullYear()) }} Grupo Nabeiro. Todos los derechos reservados.</p>
        </div>
        <div class="content-footer-itens-copyright--links">
          <a href="/storage/app/media/DeltaES/política_privacidad_clientes_amigos.pdf" target="_blank" rel="noopener">Política de privacidad</a>
          <a href="/storage/app/media/DeltaES/regulamento_movimiento_clientes_amigos_ESP.pdf" target="_blank" rel="noopener">Términos y Condiciones</a>
          <a href="/storage/app/media/DeltaES/política_de_cookies_clientes_amigos.pdf" target="_blank" rel="noopener">Política de cookies</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss">
@import './index.scss';
</style>
