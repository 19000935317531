<template>
  <section class="content-menu d-lg-none">
    <button class="content-menu--btn" :class="{ 'content-menu--btn-active': menuNavOpen }"
      @click="openMenuNav(!menuNavOpen)">
      <svg v-if="!menuNavOpen" xmlns="http://www.w3.org/2000/svg" width="32" height="22" viewBox="0 0 32 22">
        <g transform="translate(-5405.5 -11623.5)">
          <line x2="32" transform="translate(5405.5 11625.5)" fill="none" stroke="#fff" stroke-width="4" />
          <line x2="26" transform="translate(5405.5 11634.5)" fill="none" stroke="#fff" stroke-width="4" />
          <line x2="19" transform="translate(5405.5 11643.5)" fill="none" stroke="#fff" stroke-width="4" />
        </g>
      </svg>
      <svg v-else xmlns="http://www.w3.org/2000/svg" width="25.234" height="25.235" viewBox="0 0 25.234 25.235">
        <g transform="translate(-321.383 -791.883)">
          <line x2="32.686" transform="translate(322.443 816.057) rotate(-45)" fill="none" stroke="#000"
            stroke-width="3" />
          <line x2="32.686" transform="translate(322.443 792.943) rotate(45)" fill="none" stroke="#000"
            stroke-width="3" />
        </g>
      </svg>
    </button>
    <aside class="content-menu-element" :class="{ 'active-menu': menuNavOpen }">
      <div class="content-menu-element--logo">
        <img class="img-fluid" src="@/assets/img/logo-nabeiro-menu.png" alt="logo nabeiro" title="logo nabeiro" />
      </div>
      <div class="content-menu-element-links">
        <router-link tag="a" :to="{ name: 'ranking', params: { slug: campaigns.length > 0 && campaigns[0].slug } }"
          class="content--links v-content-menu" v-contentMenu="{ section: 'Menu', removeHtml: true }" @click.native="openMenuNav(false)">
          Clasificación
        </router-link>
        <router-link tag="a" :to="{ name: 'account' }" class="content--links v-content-menu"
          v-contentMenu="{ section: 'Menu', removeHtml: true }" @click.native="openMenuNav(false)">
          Mi cuenta
        </router-link>
        <button class="btn--red" v-scroll-to="'#form-opportunity'" @click="scrollToElement(); openMenuNav(false);">
          <p class="v-content-menu" v-contentMenu="{ section: 'Menu', removeHtml: true }">Submeter Oportunidade</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9">
            <path d="M4.5,0,9,8H0Z" transform="translate(8) rotate(90)" />
          </svg>
        </button>
      </div>
    </aside>
  </section>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import axios from 'axios';
import store from '@/store';

const allContents = [];
export default {
  data() {
    return {
      campaigns: [],
    }
  },

  directives: {
    contentMenu: {
      inserted: async function (el, binding) {
        const allElements = document.querySelectorAll('.v-content-menu');
        let params = binding.value ? binding.value : {};
        params.content = el.innerHTML.trim();
        (!('type' in params) ? params.type = 'text' : null);
        params.theme = process.env.VUE_APP_THEME;
        params.url_full = `${document.location.protocol}//${document.location.hostname}${document.location.pathname}`;
        allContents.push(params);

        if (allElements.length == allContents.length) {
          store.dispatch('App/loading', true);
          await axios({
            method: "POST",
            url: '/api/v1/site/get-all-contents',
            data: { allContents }
          })
            .then(response => response.data)
            .then((data) => {

              const allElements = document.querySelectorAll('.v-content-menu');
              allContents.forEach((el, index) => {
                if (el.type == 'text') {
                  allElements.forEach((el, index) => {
                    el.innerHTML = data.data[index];
                  });
                }
              });
              store.dispatch('App/loading', false);
            });
        }
      },

    }
  },
  computed: {
    ...mapGetters('User', ['user']),
    ...mapGetters('App', ['menuNavOpen']),
    ...mapState('App', ['loading']),
  },
  methods: {
    ...mapActions('User', ['logoff']),
    ...mapActions('App', ['openMenuNav']),
    scrollToElement() {
      if (this.$route.name !== 'home') {
        this.$router.push({ name: 'home' });
      }
      setTimeout(() => {
        this.$scrollTo('#form-opportunity', 800, { easing: 'linear' });
      }, 1000);
    },
    async listCampaigns() {
      await axios({
        method: 'GET',
        url: '/api/v1/campaign/list-all',
        params: {
          is_active: 1
        }
      }).then(response => response.data.data)
        .then((data) => {
          this.campaigns = data;
        })
    },
    onLogoff() {
      this.logoff();
      this.$router.push({ name: 'login' });
    }
  },
  async mounted() {
    await this.listCampaigns();
  }
}
</script>

<style lang="scss">
@import './index.scss';
</style>
